.banner {
  width: calc(100% - 48px);
  margin: 24px;
  color: #fff;
  background: linear-gradient(
      0deg,
      rgba(72, 37, 194, 0.2),
      rgba(72, 37, 194, 0.2)
    ),
    linear-gradient(177.9deg, #68a5ec 0%, #00cfaa 46.87%, #00cad7 100%);
  border-radius: 8px;
  padding: 20px;
  position: relative;
  z-index: 1;
}
.banner .cross {
  position: absolute;
  right: 10px;
  top: 10px;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 4px 4px 1px 4px;
  border-radius: 5px;
}
.banner .cross:hover {
  background: rgba(255, 255, 255, 0.4);
}
.banner h3 {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  font-size: 20px;
  flex-wrap: wrap;
}
.banner .socials button {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  padding: 8px 13.5px;
  display: flex;
  color: #fff;
  text-decoration: none;
  align-items: center;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Trebuchet MS', 'Open Sans', system-ui, -apple-system, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  justify-content: center;
  transition: all 0.1s ease-in-out;
}
.banner .socials button:hover {
  box-shadow: 0px 0px 7px rgba(255, 255, 255, 1);
  text-shadow: 0px 0px 1px rgba(255, 255, 255, 1);
}
.banner .socials img {
  margin-right: 10px;
}
.banner p {
  text-align: center;
}
.banner .socials {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, 132px);
  margin: 0 auto;
  justify-content: center;
}
.xs-hide {
  display: none;
}
.xs-show {
  display: block;
}

@media (max-width: 1200px) {
  .banner {
    margin-top: 70px;
  }
}

@media (max-width: 639px) {
  .banner {
    background: #fff;
    color: #222222;
    padding: 24px;
    margin-top: 70px;
  }
  .banner p {
    color: rgba(34, 34, 34, 0.8);
    text-align: left;
  }
  .banner .socials button {
    color: rgba(34, 34, 34, 0.8);
    background: rgba(72, 37, 194, 0.05);
  }
  .xs-hide {
    display: block;
  }
  .xs-show {
    display: none;
  }
  .banner h3 {
    text-align: left;
    justify-content: left;
    margin: 0 0 20px 0;
  }
  .banner h3 img {
    margin-bottom: 15px;
  }
  .banner .socials {
    justify-content: space-evenly;
  }
}
